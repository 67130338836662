import request from '@/utils/request'


// 查询礼物表列表
export function listGift(query) {
  return request({
    url: '/platform/gift/list',
    method: 'get',
    params: query
  })
}

// 查询礼物表分页
export function pageGift(query) {
  return request({
    url: '/platform/gift/page',
    method: 'get',
    params: query
  })
}

// 查询礼物表详细
export function getGift(data) {
  return request({
    url: '/platform/gift/detail',
    method: 'get',
    params: data
  })
}

// 新增礼物表
export function addGift(data) {
  return request({
    url: '/platform/gift/add',
    method: 'post',
    data: data
  })
}

// 修改礼物表
export function updateGift(data) {
  return request({
    url: '/platform/gift/edit',
    method: 'post',
    data: data
  })
}

// 删除礼物表
export function delGift(data) {
  return request({
    url: '/platform/gift/delete',
    method: 'post',
    data: data
  })
}
