import { render, staticRenderFns } from "./SvgaUpload.vue?vue&type=template&id=0b45894e&scoped=true"
import script from "./SvgaUpload.vue?vue&type=script&lang=js"
export * from "./SvgaUpload.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b45894e",
  null
  
)

export default component.exports