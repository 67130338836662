<template>
  <a-form-item
    :required="required"
    :label="label">
    <div v-if="svgaUrl">
      <svga-player :value="svgaUrl"></svga-player>
    </div>

    <a-upload
      accept=".svga"
      v-if="!svgaUrl"
      listType="picture-card"
      class="avatar-uploader"
      :showUploadList="false"
      :customRequest="handleSvgaChange"
    >
      <div>
        <a-icon v-if="!uploadLoading" type="plus"/>
        <div v-if="!uploadLoading" class="ant-upload-text">上传</div>
      </div>
    </a-upload>
    <div v-if="svgaUrl">
      <a-button @click="restSvga">重置</a-button>
    </div>

    <div id="test" style="position: absolute;left: -3000px;"></div>
    <canvas id="canvas_" style="display: none;"></canvas>
  </a-form-item>
</template>

<script>
import SvgaPlayer from './SvgaPlayer'
import UploadFileToOSS from "@/utils/upload-file-to-oss";
export default {
    name: 'SvgaUpload',
  components:{
      SvgaPlayer
  },
    props: {
      labelCol: Object,
      wrapperCol: Object,
      label: String,
      required: {//是必传
        type: Boolean,
        required: false,
        default: false
      },
      value: {
        type: String,
        required: false
      }
    },
    data() {
      return {
        title: "上传svga特效",
        // svgaUrl:"1058349695547609088.svga",
        svgaUrl:"",
        uploadLoading:false,
        uploadData: 0,
        url: {
        },
        validatorRules: {
          svgaUpload: {
            rules: [
              {required: this.required, message: '请上传' + this.label + '!'},
              {validator: this.checkSvgaUrl}
            ]
          },
        },
      }
    },
    methods:{
      //校验是否正在上传
      checkSvgaUrl(rule, value, callback) {
        if (this.uploadLoading === 1) {
          callback("正在上传,请稍等！");
        } else {
          callback();
        }
      },
      //上传回调
      handleSvgaChange(fileInfo) {
        let that = this;
        that.uploadLoading = true;
        new UploadFileToOSS([fileInfo.file], {
          finally: (res) => {
            console.log("res========>"+JSON.stringify(res))
            // Log.info('multi-img-upload', res);
            const ossUrl = res[0].url;
            that.svgaUrl = ossUrl;   //文件名
            fileInfo.file.ossUrl = ossUrl;
            fileInfo.file.ossName = ossUrl;
            this.$emit("change", ossUrl);
            fileInfo.onSuccess();
          },
        });

      },
      restSvga(){
        this.uploadLoading = false;
        this.svgaUrl = '';
        this.$emit("change", "");
      },
    },
    watch: {
      value: {
        immediate:true,
        handler(v){
          if (v) {
            this.svgaUrl = v;
          } else {
            this.svgaUrl = "";
          }
        }
      },
    },
}
</script>

<style scoped>

</style>